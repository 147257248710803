import clsx from "clsx";
import Link from "next/link";
import { ReactNode, forwardRef } from "react";
import Caption from "./Caption";
import Footer from "./Footer";
import Header from "./Header";
import Image from "./Image";
import ImagePlaceholder from "./ImagePlaceholder";
import Meta from "./Meta";
import Summary from "./Summary";

type RootProps = {
  href?: string;
  disableLink?: boolean;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  container?: boolean;
  theme?: "light" | "dark";
};

const Root = forwardRef<HTMLDivElement, RootProps>(
  (
    { href, disableLink, onClick, children, className = "" },
    ref
  ): JSX.Element => {
    const baseClasses = clsx(
      "@container overflow-hidden rounded-2xl bg-white border h-full w-full flex flex-col relative",
      className
    );

    return disableLink || !href ? (
      <div ref={ref} className={baseClasses} onClick={onClick}>
        {children}
      </div>
    ) : (
      <div ref={ref}>
        <Link
          href={href}
          prefetch={false}
          onClick={onClick}
          className={baseClasses}
          draggable={false}>

          {children}

        </Link>
      </div>
    );
  }
);

Root.displayName = "Root";

const Thumbnail = {
  Root,
  Image,
  Header,
  Caption,
  Meta,
  Footer,
  Summary,
  ImagePlaceholder,
};

export default Thumbnail;

export {
  Caption,
  Footer,
  Header,
  Image,
  ImagePlaceholder,
  Meta,
  Root,
  Summary,
};
