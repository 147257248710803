import { StarIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

type RatingIndicatorProps = {
  rating: number;
};

const RatingIndicator = ({ rating }: RatingIndicatorProps) => {
  return (
    <div className={clsx("flex items-center space-x-1")}>
      <StarIcon className={clsx("size-3.5")} />
      <div className={clsx("text-xs tabular-nums select-none")}>{rating}</div>
    </div>
  );
};

export default RatingIndicator;
