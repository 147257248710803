import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import NextImage, { ImageProps as NextImageProps } from "next/legacy/image";
import Link from "next/link";
import { ReactNode } from "react";

export type ImageProps = NextImageProps & {
  href?: string;
  handleClick?: () => void;
  children?: ReactNode;
};

const Image = (props: ImageProps): JSX.Element => {
  const { href, handleClick, children, alt = "", ...imageProps } = props;

  const ImageContent = () => (
    <>
      {!!imageProps.src && (
        <NextImage
          alt={alt}
          {...imageProps}
          className={clsx(
            "select-none object-cover transition-transform ease-out hover:scale-[1.025]"
          )}
          draggable={false}
          layout="fill"
          loader={cloudflareLoader}
          sizes="(max-width: 480px) 100vw, 286px"
        />
      )}
    </>
  );

  // If the image has a href, wrap it in a Link component
  if (href) {
    return (<>
      <Link
        href={href}
        onClick={handleClick}
        prefetch={false}
        className={clsx("relative aspect-square bg-zinc-100")}
        draggable={false}>

        <ImageContent />

      </Link>
      {children}
    </>);
  }

  // If the image does not have a href, render it as a div
  return (
    <div className={clsx("relative aspect-square bg-zinc-100")}>
      <ImageContent />
    </div>
  );
};

export default Image;
