/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Button from "components/Button";
import Modal from "components/Modal";
import PurchaseLinks from "components/PurchaseLinks";
import { useState } from "react";

const PURCHASE_LINK_TYPE = "purchaseLink";

const Buy = ({ entry, buttonProps = {} }) => {
  const [isModalActive, setIsModalActive] = useState(false);

  const handleClick = () => {
    if (entry.title === "Mob Gift Subscription") {
      window.open(entry.purchaseLinks[0].linkUrl, "_blank");
    } else {
      setIsModalActive(true);
    }
  };

  const handleRequestClose = () => {
    setIsModalActive(false);
  };

  // Only fetch actual links, not headings
  const links = entry.purchaseLinks
    ? entry.purchaseLinks.filter((l) => l.typeHandle === PURCHASE_LINK_TYPE)
    : [];

  if (links.length === 0) return null;

  const label = entry.purchaseButtonLabel ? entry.purchaseButtonLabel : "Buy";

  return (
    <div className="Buy">
      <div className="Buy__button">
        <Button
          label={label}
          onClick={handleClick}
          attributes={{
            "data-entry-id": entry.id,
            "data-entry-title": entry.title,
          }}
          {...buttonProps}
          theme="light"
          isSmall
        />
      </div>

      <Modal isOpen={isModalActive} onRequestClose={handleRequestClose}>
        <h2 className="Modal__heading">Where to Buy</h2>
        <PurchaseLinks entry={entry} />
      </Modal>
    </div>
  );
};

export default Buy;
